import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface CashDeskItem {
  id: string;
  username: string;
}

@Module
export default class CashDeskModule extends VuexModule {
  public list;
  public detail;
  public token;

  /**
   * Return cashier items
   * @returns BotItem
   */
  get getCashierList(): CashDeskItem {
    return this.list;
  }

  get getCashierDetail(): CashDeskItem {
    return this.detail;
  }

  get getCashierModalToken() {
    return this.token;
  }

  @Mutation
  [Mutations.SET_CASHIER_LIST](payload): void {
    this.list = payload;
  }

  @Mutation
  [Mutations.SET_CASHIER_DETAIL](payload): void {
    this.detail = payload;
  }

  @Mutation
  [Mutations.SET_CASHIER_TOKEN](payload): void {
    this.token = payload.token;
  }

  @Action
  [Actions.REQUEST_CASHIER_LIST](payload) {
    return ApiService.get(`/cash-desk/${payload.id}/cashiers`).then(
      (payload) => {
        const data = payload.data;

        this.context.commit(Mutations.SET_CASHIER_LIST, data);
      }
    );
  }

  @Action
  [Actions.REQUEST_CURRENT_CASHIER](payload) {
    return ApiService.get(`/cashier/${payload.id}`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_CASHIER_DETAIL, data);
    });
  }

  @Action
  [Actions.UPDATE_CURRENT_CASHIER](payload) {
    return ApiService.post(`/cashier/${payload.model.id}`, payload.model);
  }

  @Action
  [Actions.REGISTER_CASHIER_BY_PIN](payload) {
    return ApiService.post(
      `cashier/${payload.model.cashier}/get-token/by-pin`,
      payload.model
    );
  }

  @Action
  [Actions.CREATE_NEW_CASHIER](payload) {
    return ApiService.post(`/cashier`, payload.model);
  }

  @Action
  [Actions.REQUEST_CASHIER_API_TOKEN](payload) {
    return ApiService.post(`/auth/get-cashier-jwt/${payload.id}`, {}).then(
      (response) => {
        const data = response.data;
        this.context.commit(Mutations.SET_CASHIER_TOKEN, data);
      }
    );
  }
}
