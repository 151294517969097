enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  GET_USER_LIST = "getUserList",

  GET_DASHBOARD_STATS = "getDashboardStats",
  GET_MESSAGE_HISTORY = "getHistoryMessages",

  REQUEST_BOT_LIST = "requestBotList",
  REQUEST_CURRENT_BOT = "getCurrentBot",

  UPDATE_CURRENT_BOT = "updateCurrentBot",

  REGISTER_NEW_BOT = "registerNewBot",

  CREATE_FLASH_MESSAGE = "createFlashMessage",
  MARK_READ = "markReadFlashMessage",
  DELETE_FLASH_MESSAGE = "deleteFlashMessage",

  TOGGLE_AJAX_STATE = "toggleAjaxState",

  REQUEST_BOT_SUBSCRIBERS = "requestBotSubscribers",

  CREATE_NEW_PROJECT = "createNewProject",
  REQUEST_PROJECT_LIST = "requestProjectList",
  REQUEST_CURRENT_PROJECT = "requestCurrentProject",
  UPDATE_CURRENT_PROJECT = "updateCurrentProject",

  REQUEST_LOCATION_LIST = "requestLocationList",
  REQUEST_CURRENT_LOCATION = "requestCurrentLocation",
  UPDATE_CURRENT_LOCATION = "updateCurrentLocation",
  CREATE_NEW_LOCATION = "createNewLocation",

  REQUEST_CASH_DESK_LIST = "requestCashDeskList",
  REQUEST_CURRENT_CASH_DESK = "requestCashDeskLocation",
  UPDATE_CURRENT_CASH_DESK = "updateCurrentCashDesk",
  CREATE_NEW_CASH_DESK = "createNewCashDesk",

  REQUEST_CASHIER_LIST = "requestCashierList",
  REQUEST_CURRENT_CASHIER = "requestCurrentCashier",
  UPDATE_CURRENT_CASHIER = "updateCurrentCashier",
  CREATE_NEW_CASHIER = "createNewCashier",
  REGISTER_CASHIER_BY_PIN = "registerCashierByPin",
  REQUEST_CASHIER_API_TOKEN = "requestCashierApiToken",

  TOGGLE_SUB_VIEW_STATE = "toggleSubViewStateAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_DASHBOARD_STATS = "setDashboardStats",
  SET_MESSAGE_HISTORY = "setMessageHistory",
  SET_BOTS_LIST = "setBotsList",
  SET_BOT_DETAIL = "setBotDetail",

  SET_MESSAGE = "setMessage",
  SET_READ = "setRead",
  DELETE_MESSAGE = "deleteMessage",

  TOGGLE_AJAX = "toggleAjax",

  SET_BOT_SUBSCRIBERS = "setBotSubscribers",

  SET_PROJECTS_LIST = "setProjectsList",
  SET_PROJECT_DETAIL = "setProjectsDetail",

  SET_LOCATIONS_LIST = "setLocationsList",
  SET_LOCATION_DETAIL = "setLocationDetail",

  SET_CASH_DESK_LIST = "setCashDeskList",
  SET_CASH_DESK_DETAIL = "setCashDeskDetail",

  SET_CASHIER_LIST = "setCashierList",
  SET_CASHIER_DETAIL = "setCashierDetail",

  TOGGLE_SUB_VIEW_STATE = "toggleSubViewStateMutation",

  SET_CASHIER_TOKEN = "setCashierToken",
}

export { Actions, Mutations };
