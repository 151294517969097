import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import("@/views/Project.vue"),
      },
      {
        path: "/receipt",
        name: "receipt",
        component: () => import("@/views/Receipt.vue"),
      },
      {
        path: "/report",
        name: "report",
        component: () => import("@/views/Report.vue"),
      },
      {
        path: "/project/create",
        name: "project_create",
        component: () => import("@/views/ProjectCreate.vue"),
      },
      {
        path: "/project/:id/location/create",
        name: "location_create",
        component: () => import("@/views/LocationCreate.vue"),
      },
      {
        path: "/project/:id",
        name: "project_detail",
        component: () => import("@/views/ProjectDetail.vue"),
      },
      {
        path: "/location/:id/cashdesk",
        name: "location_cashdesks",
        component: () => import("@/views/LocationCashdesks.vue"),
      },
      {
        path: "/location/:id/cashdesk/create",
        name: "cashdesk_create",
        component: () => import("@/views/CashdeskCreate.vue"),
      },
      {
        path: "/cashdesk/:id",
        name: "cashdesk_detail",
        component: () => import("@/views/CashdeskDetail.vue"),
      },
      {
        path: "/cashdesk/:id/cashier/create",
        name: "cashier_create",
        component: () => import("@/views/CashierCreate.vue"),
      },
      {
        path: "/bot",
        name: "bot",
        component: () => import("@/views/Bot.vue"),
      },
      {
        path: "/bot/:id",
        name: "bot_detail",
        component: () => import("@/views/BotDetail.vue"),
      },
      {
        path: "/bot/create",
        name: "bot_create",
        component: () => import("@/views/BotCreate.vue"),
      },
      {
        path: "/bot/:id/subscribers",
        name: "bot_subscribers",
        component: () => import("@/views/BotSubscribers.vue"),
      },
      {
        path: "/bot/:id/history",
        name: "bot_messages",
        component: () => import("@/views/BotHistory.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
