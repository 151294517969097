import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface LocationItem {
  id: string;
  title: string;
  created_at: string;
}

@Module
export default class ProjectModule extends VuexModule {
  public list;
  public detail;

  /**
   * Return location items
   * @returns BotItem
   */
  get getLocationsList(): LocationItem {
    return this.list;
  }

  get getLocationDetail(): LocationItem {
    return this.detail;
  }

  @Mutation
  [Mutations.SET_LOCATIONS_LIST](payload): void {
    this.list = payload;
  }

  @Mutation
  [Mutations.SET_LOCATION_DETAIL](payload): void {
    this.detail = payload;
  }

  @Action
  [Actions.REQUEST_LOCATION_LIST](payload) {
    return ApiService.get(`/project/${payload.model.id}/locations`).then(
      (payload) => {
        const data = payload.data;

        this.context.commit(Mutations.SET_LOCATIONS_LIST, data);
      }
    );
  }

  @Action
  [Actions.REQUEST_CURRENT_LOCATION](payload) {
    return ApiService.get(`/location/${payload.id}`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_PROJECT_DETAIL, data);
    });
  }

  @Action
  [Actions.UPDATE_CURRENT_LOCATION](payload) {
    return ApiService.post(`/location/${payload.model.id}`, payload.model);
  }

  @Action
  [Actions.CREATE_NEW_LOCATION](payload) {
    return ApiService.post(`/location`, payload.model);
  }
}
