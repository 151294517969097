import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface DashboardStats {
  messages: string;
  bots: string;
  subscribers: string;
  messages_chart: [];
  bots_chart: [];
}

@Module
export default class DashboardModule extends VuexModule {
  public dashboardStats;
  /**
   * Get current user object
   * @returns DashboardStats
   */
  get getIndexStats(): DashboardStats {
    return this.dashboardStats;
  }

  @Mutation
  [Mutations.SET_DASHBOARD_STATS](payload): void {
    this.dashboardStats = payload;
  }

  @Action
  [Actions.GET_DASHBOARD_STATS]() {
    return ApiService.get("/api/dashboard").then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_DASHBOARD_STATS, data);
    });
  }
}
