import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface HistoryItem {
  id: string;
  message: string;
  success: boolean;
  telegram_bot: [];
  receiver: [];
}

@Module
export default class HistoryModule extends VuexModule {
  public history;
  /**
   * Return history items
   * @returns HistoryItem
   */
  get getMessageHistory(): HistoryItem {
    return this.history;
  }

  @Mutation
  [Mutations.SET_MESSAGE_HISTORY](payload): void {
    this.history = payload;
  }

  @Action
  [Actions.GET_MESSAGE_HISTORY](payload) {
    let url = "/api/message-history";

    if (payload) {
      url = `${url}/${payload.id}`;
    }

    return ApiService.get(url).then((response) => {
      const data = response.data;

      this.context.commit(Mutations.SET_MESSAGE_HISTORY, data);
    });
  }
}
