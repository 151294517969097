import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import DashboardModule from "@/store/modules/DashboardModule";
import HistoryModule from "@/store/modules/HistoryModule";
import BotModule from "@/store/modules/BotModule";
import NotifyModule from "@/store/modules/NotifyModule";
import AjaxModule from "@/store/modules/AjaxModule";
import ProjectModule from "@/store/modules/ProjectModule";
import LocationModule from "@/store/modules/LocationModule";
import CashDeskModule from "@/store/modules/CashDeskModule";
import CashierModule from "@/store/modules/CashierModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    DashboardModule,
    HistoryModule,
    BotModule,
    NotifyModule,
    AjaxModule,
    ProjectModule,
    LocationModule,
    CashDeskModule,
    CashierModule,
  },
});

export default store;
