import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface BotItem {
  id: string;
  description: string;
  created_at: string;
}

export interface BotSubscriber {
  id: string;
  name: string;
  phone: string;
}

@Module
export default class BotModule extends VuexModule {
  public list;
  public detail;
  public botSubscribers;

  /**
   * Return bot items
   * @returns BotItem
   */
  get getBotsList(): BotItem {
    return this.list;
  }

  get getBotDetail(): BotItem {
    return this.detail;
  }

  /**
   * Return bot subscribers
   * @returns BotSubscriber
   */
  get getBotSubscribersList(): BotSubscriber {
    return this.botSubscribers;
  }

  @Mutation
  [Mutations.SET_BOTS_LIST](payload): void {
    this.list = payload;
  }

  @Mutation
  [Mutations.SET_BOT_DETAIL](payload): void {
    this.detail = payload;
  }

  @Mutation
  [Mutations.SET_BOT_SUBSCRIBERS](payload): void {
    this.botSubscribers = payload;
  }

  @Action
  [Actions.REQUEST_BOT_LIST]() {
    return ApiService.get(`/api/bot`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_BOTS_LIST, data);
    });
  }

  @Action
  [Actions.REQUEST_CURRENT_BOT](payload) {
    return ApiService.get(`/api/bot/${payload.id}`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_BOT_DETAIL, data);
    });
  }

  @Action
  [Actions.UPDATE_CURRENT_BOT](payload) {
    return ApiService.post(`/api/bot/${payload.model.id}`, payload.model);
  }

  @Action
  [Actions.REGISTER_NEW_BOT](payload) {
    return ApiService.post(`/api/bot`, payload.model);
  }

  @Action
  [Actions.REQUEST_BOT_SUBSCRIBERS](payload) {
    return ApiService.get(`/api/bot/${payload.id}/subscribers`).then(
      (payload) => {
        const data = payload.data;

        this.context.commit(Mutations.SET_BOT_SUBSCRIBERS, data);
      }
    );
  }
}
