import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class AjaxModule extends VuexModule {
  public state;
  public sub_view;
  public sub_view_current;

  get getAjaxState() {
    return this.state;
  }

  get getSubViewState() {
    return this.state;
  }

  @Mutation
  [Mutations.TOGGLE_AJAX](): void {
    this.state = !this.state;
    console.log(this.state);
  }

  @Mutation
  [Mutations.TOGGLE_SUB_VIEW_STATE](): void {
    this.sub_view = !this.sub_view;
    console.log(this.sub_view);
  }

  @Action
  [Actions.TOGGLE_AJAX_STATE]() {
    this.context.commit(Mutations.TOGGLE_AJAX);
  }

  @Action
  [Actions.TOGGLE_SUB_VIEW_STATE]() {
    this.context.commit(Mutations.TOGGLE_SUB_VIEW_STATE);
  }
}
