import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface NotifyItem {
  message: string;
  class: boolean;
  is_read: boolean;
}

@Module
export default class NotifyModule extends VuexModule {
  public message;
  /**
   * Return history items
   * @returns NotifyItem
   */
  get getFlashMessage(): NotifyItem {
    return this.message;
  }

  @Mutation
  [Mutations.SET_MESSAGE](payload): void {
    this.message = payload;
  }

  @Mutation
  [Mutations.SET_READ](): void {
    this.message.is_read = true;
  }

  @Mutation
  [Mutations.DELETE_MESSAGE](): void {
    this.message = false;
  }

  @Action
  [Actions.CREATE_FLASH_MESSAGE](payload) {
    this.context.commit(Mutations.SET_MESSAGE, payload);
  }

  @Action
  [Actions.MARK_READ]() {
    this.context.commit(Mutations.SET_READ);
  }

  @Action
  [Actions.DELETE_FLASH_MESSAGE](payload) {
    this.context.commit(Mutations.DELETE_MESSAGE, payload);
  }
}
