import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

export interface ProjectItem {
  id: string;
  title: string;
  created_at: string;
}

@Module
export default class ProjectModule extends VuexModule {
  public list;
  public detail;

  /**
   * Return project items
   * @returns BotItem
   */
  get getProjectsList(): ProjectItem {
    return this.list;
  }

  get getProjectDetail(): ProjectItem {
    return this.detail;
  }

  @Mutation
  [Mutations.SET_PROJECTS_LIST](payload): void {
    this.list = payload;
  }

  @Mutation
  [Mutations.SET_PROJECT_DETAIL](payload): void {
    this.detail = payload;
  }

  @Action
  [Actions.REQUEST_PROJECT_LIST]() {
    return ApiService.get(`/project`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_PROJECTS_LIST, data);
    });
  }

  @Action
  [Actions.REQUEST_CURRENT_PROJECT](payload) {
    return ApiService.get(`/project/${payload.id}`).then((payload) => {
      const data = payload.data;

      this.context.commit(Mutations.SET_PROJECT_DETAIL, data);
    });
  }

  @Action
  [Actions.UPDATE_CURRENT_PROJECT](payload) {
    return ApiService.post(`/project/${payload.model.id}`, payload.model);
  }

  @Action
  [Actions.CREATE_NEW_PROJECT](payload) {
    return ApiService.post(`/project`, payload.model);
  }
}
